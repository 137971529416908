import { graphql } from 'gatsby'
import { Box, Heading } from '@chakra-ui/react'
import { SectionContainer } from '@components/SectionContainer'
import { ExpandableList } from '@components/ExpandableList'
import styled from '@emotion/styled'

const PageStyles = styled.div`
  a {
    color: #ee3b66;
    text-decoration: underline;
  }
`

const FaqPage = ({ data }) => {
  const faqs = data.faqJson

  return (
    <PageStyles>
      <SectionContainer>
        <Box maxW="container.md" mx="auto" pb={24}>
          <Box>
            <Heading fontSize="4xl" mb={8} lineHeight="1em">
              {faqs.title}
            </Heading>
            {faqs.sections?.map(section => (
              <Box key={section.title}>
                <Heading
                  as="h4"
                  fontSize="2xl"
                  lineHeight="1em"
                  mb={8}
                  mt={24}
                  variant="sans-serif"
                >
                  {section.title}
                </Heading>
                <ExpandableList data={section.questions} />
              </Box>
            ))}
          </Box>
        </Box>
      </SectionContainer>
    </PageStyles>
  )
}

export default FaqPage

export const query = graphql`
  query FaqQuery {
    faqJson {
      title
      sections {
        title
        questions {
          question
          answer
        }
      }
    }
  }
`
